import React, { useEffect, useState } from 'react'
import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'

interface Props {
  onResponse: (response: any) => void
}

const ErrorInvalidHandler = ({ onResponse }: Props) => {
  return (
    <>
      <h1>
        <span>Oh, oh</span>
        No ha funcionado esta vez.
      </h1>
      <hr />
      <p>
        No hemos podido validar todos los puntos para garantizar la lectura de tu cara. ¿Podrías verificar las instrucciones e intentarlo de nuevo, por favor?
      </p>

      <ul className="instruction-list list-group">
        <li className="list-group-item">
          <i>
            <BsSun />
          </i>
          Encuentra un lugar bien iluminado y con un fondo neutro, como una pared blanca.
        </li>
        <li className="list-group-item">
          <i>
            <BsPersonBoundingBox />
          </i>
          Ajusta la imagen de tu rostro en el marco azul.
        </li>
        <li className="list-group-item">
          <i>
            <BsPeople />
          </i>
          Asegúrate de que no haya otra persona en el campo de captura.
        </li>
        <li className="list-group-item">
          <i>
            <BsEmojiWink />
          </i>
          No sonrías ni parpadees.
        </li>
        <li className="list-group-item">
          <i>
            <BsEmojiSunglasses />
          </i>
          No uses gafas, mascarillas ni gorras.
        </li>
      </ul>
      <div className="form-group form-submit">
        <a
          className="btn btn-primary form-control form-control-lg"
          onClick={() => onResponse({ step: 'faceproceed' })}
        >
          Volver a intentar
        </a>
      </div>
    </>
  )
}

export default ErrorInvalidHandler
