import React, { useEffect, useState } from 'react'
import qrCode from './../../images/qr_code.png'

import './ErrorsHandlers.sass'

const MobileHandler = () => {
  const makeid = (length: any) => {
    let result = ''
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  const [currentUrl, setCurrentUrl] = React.useState(window.location.origin+window.location.pathname)

  const [userToken, setUserToken] = React.useState('$2a$1.xMN0$caE6jqQer67KnCYUdpMluyZ9aC5Ujs1xrVJr5jChn6TuXiexS')

  React.useEffect(() => {
    if (window.sessionStorage.getItem('token')) {
      setUserToken(window.sessionStorage.getItem('token') + '')
    }
  }, [])

  return (
    <>
      <h1>
        ¡<span>Empecemos</span>!
      </h1>
      <hr />
      <p>
        Para una mejor experiencia, pedimos que acceda desde su dispositivo móvil.
      </p>
      <p>Escanea este código QR con tu móvil o accede al enlace de abajo:</p>


      <img
        src={
          `https://api.qrserver.com/v1/create-qr-code/?size=300x300&bgcolor=ffffff&data=${currentUrl}?token=` +
          userToken
        }
        className="qr-code"
        alt="OnBoarding"
      />
      <a
        className="qr-link"
        href={`${currentUrl}?token=` + userToken}
      >
        {`${currentUrl}?token=` + userToken}
      </a>
    </>
  )
}

export default MobileHandler
