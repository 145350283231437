import React, { useEffect, useState } from 'react'

const SuccessHandler = () => {
  window.sessionStorage.removeItem('token')

  // setTimeout(() => {
  //   window.location.href =
  //     'https://ingressospalmeiras.com.br/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoicGlwYXJhcGlybyJ9.DNxVLcZW3nAkWE3PwewIj0XFvTOUrbQiTJJ0kbN8Nhk'
  // }, 4000)
  return (
    <>
      <h1>
        <span>¡Todo </span>listo!
      </h1>
      <hr />
      <p className="text-center">
        Se ha completado el registro de tu rostro con éxito
        <br />
        <br />
        ¡Gracias!
      </p>

    </>
  )
}

export default SuccessHandler
